import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RetryConfig, map, retry, tap } from 'rxjs';
import { PermissionsService } from '../shared/services/permissions/permissions.service';
import { TranslationService } from '../shared/transloco/services/translation.service';

export const AuthorizationGuard: CanActivateFn = (route, state) => {
    const msalGuard = inject(MsalGuard);
    const permissionsService = inject(PermissionsService);
    const router = inject(Router);
    const translationService = inject(TranslationService);

    return msalGuard.canActivate(route, state).pipe(
        tap(() => {
            if (permissionsService.permissions == null) {
                throw new Error(translationService.translate('notAuthorized.noPermission'));
            }
        }),
        map((canActivate) => {
            const permissions = route.data?.permissions ?? [];
            const hasPermissions = permissionsService.hasPermissions(permissions);

            return (canActivate && hasPermissions) || router.createUrlTree(['not-authorized']);
        }),
        retry({ delay: 1000, count: 5 } as RetryConfig)
    );
};
