<div class="modal-header confirmation-modal-default-bg-color">
    <h4 class="modal-title text-uppercase" id="modal-title"><i class="fa-solid fa-wand-magic-sparkles fa-sm me-1"></i>{{ 'shortcutKeys.aiAssistant' | translation | async }}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body align-items-center">
    <textarea #inputField rows="5" class="border w-100 none-outline"></textarea>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="close()">{{ 'actions.ok' | translation | async }}</button>
</div>
