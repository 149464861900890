import { inject, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { GlobalShortcutKeys } from '../../enumerations/global.shortcutKeys';
import { TranslationService } from '../../transloco/services/translation.service';
import { IConfirmationButton } from './confirmation-button';
import { getDefaultConfirmationButtons, getUnsavedChangesConfirmationButtons } from './confirmation-buttons-repository';
import { ConfirmationModalRef } from './confirmation-modal-ref';
import { ConfirmationModalComponent } from './confirmation-modal.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationModalService {
    private modalRef: NgbModalRef;
    private translationService = inject(TranslationService);

    public set isLoading$(value$: Observable<boolean>) {
        const confirmationModalComponent = this.modalRef.componentInstance as ConfirmationModalComponent;
        confirmationModalComponent.isLoading$ = value$;
    }

    constructor(private ngbModalService: NgbModal) {}

    public async open(title: string, message: string, confirmationButtons?: IConfirmationButton[], container?: HTMLElement, isWarningDialog = false) {
        this.ngbModalService.dismissAll();
        this.modalRef = this.ngbModalService.open(ConfirmationModalComponent, { container: container });

        const confirmationModalComponent = this.modalRef.componentInstance as ConfirmationModalComponent;
        confirmationModalComponent.title = title;
        confirmationModalComponent.message = message;
        confirmationModalComponent.isWarningDialog = isWarningDialog;

        for (const btn of confirmationButtons) {
            btn.click = () => {
                this.modalRef.close(btn.result);
            };
        }

        this.modalRef.componentInstance.confirmationButtons = confirmationButtons;

        try {
            return await this.modalRef.result;
        } catch {
            // do nothing
        }
    }

    public async openDefaultConfirmation(title: string, message: string, container?: HTMLElement) {
        return await this.open(title, message, getDefaultConfirmationButtons(this.translationService), container);
    }

    public async openUnsavedChanges(title: string, message: string, container?: HTMLElement) {
        return await this.open(title, message, getUnsavedChangesConfirmationButtons(this.translationService), container);
    }

    public async openWarning(title: string, message: string, container?: HTMLElement, delayedButtonText: string = null): Promise<boolean> {
        const defaultButtons = [...getDefaultConfirmationButtons(this.translationService)] as Array<IConfirmationButton>;

        if (delayedButtonText != null) {
            defaultButtons[0].setDelay(5);
            defaultButtons[0].title = delayedButtonText;
        }

        defaultButtons[0].ngClass = ['btn', 'confirmation-modal-warning-button'];

        const result = this.open(title, message, defaultButtons, container, true);
        return result;
    }

    public openCallback(title: string, message: string, confirmationButtons?: IConfirmationButton[], container?: HTMLElement, isWarningDialog = true, callback?: (result: ConfirmationModalRef) => void) {
        this.ngbModalService.dismissAll();
        this.modalRef = this.ngbModalService.open(ConfirmationModalComponent, { container: container });

        const confirmationModalRef = new ConfirmationModalRef();
        confirmationModalRef.confirmationModalComponent = this.modalRef.componentInstance as ConfirmationModalComponent;
        confirmationModalRef.confirmationModalComponent.title = title;
        confirmationModalRef.confirmationModalComponent.message = message;
        confirmationModalRef.confirmationModalComponent.isWarningDialog = isWarningDialog;

        for (const btn of confirmationButtons) {
            if (btn.shortcutKey === GlobalShortcutKeys.Close) {
                btn.click = () => this.close();
                continue;
            }

            btn.click = () => {
                confirmationModalRef.buttonClicked.emit(btn.result);
            };
        }

        confirmationModalRef.confirmationModalComponent.confirmationButtons = confirmationButtons;
        callback(confirmationModalRef);
    }

    public close() {
        this.modalRef.close();
    }
}
