import { ComponentFactoryResolver, ComponentRef, DestroyRef, inject, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { GlobalShortcutKeys } from 'src/app/shared/enumerations/global.shortcutKeys';
import languageDictionary from 'src/app/shared/transloco/default';
import { TranslationService } from 'src/app/shared/transloco/services/translation.service';
import { NestedPropertyOf } from 'src/app/shared/transloco/types/nested-key-of.type';
import { IConfirmationButton } from '../../confirmation-modal/confirmation-button';
import { getUnsavedChangesConfirmationButtons } from '../../confirmation-modal/confirmation-buttons-repository';
import { ConfirmationModalService } from '../../confirmation-modal/confirmation-modal.service';
import { ActiveToolboxComponent } from './activeToolboxComponent';
import { StandardToolboxMode } from './standard-toolbox-mode';
import { StandardToolboxRef, ToolboxData } from './standard-toolbox-ref';
import { StandardToolboxComponent } from './standard-toolbox.component';

@Injectable({
    providedIn: 'root',
})
export class StandardToolboxService {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    private result: Promise<any>;
    private resolve: (result?: object) => void;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    private activeComponentRef: Map<string, ComponentRef<any>>;

    private subscription = new Subscription();

    private translationService = inject(TranslationService);
    private detsroyRef = inject(DestroyRef);

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        this.activeComponentRef = new Map<string, ComponentRef<any>>();

        this.detsroyRef.onDestroy(() => this.subscription.unsubscribe());
    }

    public open<T>(
        toolboxId: string,
        currentComponent: ViewContainerRef,
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        toolbox: any,
        title: string | NestedPropertyOf<typeof languageDictionary>,
        args?: T,
        displayCloseButton: boolean = true,
        mode: StandardToolboxMode = StandardToolboxMode.Over
    ): ActiveToolboxComponent<T> {
        this.init();

        // Insert sidebar to the current component
        currentComponent.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(StandardToolboxComponent);

        //Add toolbox to sidebar
        const toolboxComponent = this.componentFactoryResolver.resolveComponentFactory(toolbox);
        const toolboxRef = new StandardToolboxRef();
        const standardToolboxRefInjector = Injector.create({
            providers: [
                { provide: StandardToolboxRef, useValue: toolboxRef },
                { provide: ToolboxData, useValue: args },
            ],
        });
        const componentReference = currentComponent.createComponent(componentFactory, undefined, standardToolboxRefInjector);

        this.setToolboxTitle(title, componentReference);

        componentReference.instance.displayCloseButton = displayCloseButton;
        componentReference.instance.mode = mode;

        const activeComponent = componentReference.instance.toolboxContainer.createComponent(toolboxComponent, undefined, standardToolboxRefInjector);
        this.activeComponentRef.set(toolboxId, activeComponent);

        this.commandButtonsClick(toolboxId, toolboxRef, componentReference);

        componentReference.changeDetectorRef.detectChanges();

        return new ActiveToolboxComponent<T>(activeComponent, this.result);
    }

    private commandButtonsClick(toolboxId: string, toolboxRef: StandardToolboxRef, componentReference: ComponentRef<StandardToolboxComponent>) {
        const subscription = new Subscription();
        subscription.add(
            toolboxRef.closeToolbox.pipe(take(1)).subscribe((entity) => {
                const activeComponent = this.activeComponentRef.get(toolboxId);
                activeComponent?.destroy();
                this.activeComponentRef.delete(toolboxId);
                componentReference.destroy();

                if (entity) {
                    this.resolve(entity as object);
                }

                subscription.unsubscribe();
            })
        );
    }

    public hasChanges(toolboxId: string) {
        const activeComponent = this.activeComponentRef.get(toolboxId);
        return activeComponent?.instance?.hasChanges() === true;
    }

    public async openUnsavedChangesDialog<T>(title: string, toolboxId: string, confirmationModalService: ConfirmationModalService, standardToolbox: ActiveToolboxComponent<T>) {
        const hasChanges = this.hasChanges(toolboxId);

        if (hasChanges) {
            const areChangesValid = standardToolbox.componentRef?.form?.valid === true;

            const confirmationMessage = areChangesValid
                ? this.translationService.translate('standardToolbox.confirmationDialog.unsavedChangesDialogMessage', { title })
                : this.translationService.translate('standardToolbox.confirmationDialog.invalidChangesMessage');

            const confirmationButtons = getUnsavedChangesConfirmationButtons(this.translationService) as Array<IConfirmationButton>;

            confirmationButtons[0].ngClass = { btn: true, 'btn-primary': true, 'd-none': !areChangesValid };
            confirmationButtons[1].ngClass = {
                btn: true,
                'btn-secondary': areChangesValid,
                'btn-primary': !areChangesValid,
            };
            confirmationButtons[1].shortcutKey = areChangesValid ? null : GlobalShortcutKeys.SaveAndClose;

            try {
                const result = await confirmationModalService.open(this.translationService.translate('defaults.confirmationDialog.unsavedChangesDialogTitle'), confirmationMessage, confirmationButtons);

                if (result === 'SaveChanges') {
                    standardToolbox.componentRef.save();
                    return true;
                } else if (result === 'DiscardChanges') {
                    standardToolbox.componentRef.cancel();
                    return true;
                } else if (result === 'Cancel' || result === false) {
                    return false;
                }
            } catch {
                return false;
            }
        }
        return true;
    }

    private init() {
        this.result = new Promise((resolve) => {
            this.resolve = resolve;
        });
        this.result.then(null, () => {});
    }

    private setToolboxTitle(title: string, componentReference: ComponentRef<StandardToolboxComponent>) {
        const titleTranslationKey = title as NestedPropertyOf<typeof languageDictionary>;
        if (titleTranslationKey) {
            this.subscription.add(
                this.translationService.afterLanguageChanged$
                    .pipe(
                        tap(() => {
                            componentReference.instance.title = this.translationService.translate(titleTranslationKey);
                        })
                    )
                    .subscribe()
            );
        } else {
            componentReference.instance.title = title;
        }
    }
}
