import { DestroyRef, inject, Injectable } from '@angular/core';
import { LoadOptions, TranslocoService } from '@jsverse/transloco';
import { distinctUntilChanged, filter, fromEvent, startWith, Subscription, switchMap, tap } from 'rxjs';
import languageDictionary from '../default';
import { NestedPropertyOf } from '../types/nested-key-of.type';

@Injectable()
export class TranslationService {
    private translocoService = inject(TranslocoService);
    private destroyRef = inject(DestroyRef);
    private subscription = new Subscription();

    public languageChanges$ = this.translocoService.langChanges$.pipe(startWith(this.translocoService.getActiveLang()));

    public afterLanguageChanged$ = this.languageChanges$.pipe(
        distinctUntilChanged(),
        filter((language) => language != null),
        switchMap((language) => this.translocoService.load(language))
    );

    constructor() {
        this.subscription.add(
            fromEvent(document, 'click')
                .pipe(
                    tap(() => {
                        // Translate calendar months and week days.
                        //This way is only workaround for now, because the native i18n does not work properly for ng-bootstrap and ngx-daterangepicker-bootstrap.
                        this.translateCalendarMonths();
                        this.translateCalendarWeekDays();
                    })
                )
                .subscribe()
        );

        this.onDestroy();
    }

    public getActiveLanguage() {
        return this.translocoService.getActiveLang();
    }

    public setActiveLanguage(language: string) {
        return this.translocoService.setActiveLang(language);
    }

    public load(path: string, options?: LoadOptions) {
        return this.translocoService.load(path, options);
    }

    public getAvailableLanguages() {
        const availableLanguages = this.translocoService.getAvailableLangs();
        return availableLanguages;
    }

    public translate(dictionaryKey: NestedPropertyOf<typeof languageDictionary>, params?: { [key: string]: string | number }) {
        return this.translocoService.translate(dictionaryKey, params);
    }

    private onDestroy() {
        this.destroyRef.onDestroy(() => this.subscription.unsubscribe());
    }

    private translateCalendarWeekDays() {
        document.querySelectorAll('.ngb-dp-week > ngb-dp-weekday, .week-days > th > span').forEach((item, index) => {
            const indexOfWeekDay = index % 7;

            switch (indexOfWeekDay) {
                case 0:
                    item.textContent = this.translate('time.monday');
                    break;
                case 1:
                    item.textContent = this.translate('time.tuesday');
                    break;
                case 2:
                    item.textContent = this.translate('time.wednesday');
                    break;
                case 3:
                    item.textContent = this.translate('time.thursday');
                    break;
                case 4:
                    item.textContent = this.translate('time.friday');
                    break;
                case 5:
                    item.textContent = this.translate('time.saturday');
                    break;
                case 6:
                    item.textContent = this.translate('time.sunday');
                    break;
            }
        });
    }

    private translateCalendarMonths() {
        document.querySelectorAll('.monthselect > option, select[aria-label="Select month"].form-select > option').forEach((item, index) => {
            const indexOfMonth = index % 12;

            switch (indexOfMonth) {
                case 0:
                    item.textContent = this.translate('time.months.january');
                    break;
                case 1:
                    item.textContent = this.translate('time.months.february');
                    break;
                case 2:
                    item.textContent = this.translate('time.months.march');
                    break;
                case 3:
                    item.textContent = this.translate('time.months.april');
                    break;
                case 4:
                    item.textContent = this.translate('time.months.may');
                    break;
                case 5:
                    item.textContent = this.translate('time.months.june');
                    break;
                case 6:
                    item.textContent = this.translate('time.months.july');
                    break;
                case 7:
                    item.textContent = this.translate('time.months.august');
                    break;
                case 8:
                    item.textContent = this.translate('time.months.september');
                    break;
                case 9:
                    item.textContent = this.translate('time.months.october');
                    break;
                case 10:
                    item.textContent = this.translate('time.months.november');
                    break;
                case 11:
                    item.textContent = this.translate('time.months.december');
                    break;
            }
        });
    }
}
