import { GlobalShortcutKeys } from '../../enumerations/global.shortcutKeys';
import { TranslationService } from '../../transloco/services/translation.service';
import { ConfirmationButton, IConfirmationButton } from './confirmation-button';

export const getDefaultConfirmationButtons = (translationService: TranslationService) =>
    [
        new ConfirmationButton(translationService, 'btn-yes', 'defaults.yes', true, ['btn', 'btn-primary'], GlobalShortcutKeys.SaveAndClose),
        new ConfirmationButton(translationService, 'btn-cancel', 'actions.cancel', false, ['btn', 'btn-secondary'], GlobalShortcutKeys.Close),
    ] as IConfirmationButton[];

export const getCancelButton = (translationService: TranslationService) => new ConfirmationButton(translationService, 'btn-cancel', 'actions.cancel', 'Cancel', ['btn', 'btn-secondary'], GlobalShortcutKeys.Close) as IConfirmationButton;

export const getUnsavedChangesConfirmationButtons = (translationService: TranslationService) =>
    [
        new ConfirmationButton(translationService, 'btn-save-changes', 'actions.saveChanges', 'SaveChanges', ['btn', 'btn-primary'], GlobalShortcutKeys.SaveAndClose),
        new ConfirmationButton(translationService, 'btn-discard-changes', 'actions.discardChanges', 'DiscardChanges', ['btn', 'btn-secondary']),
        new ConfirmationButton(translationService, 'btn-cancel', 'actions.cancel', 'Cancel', ['btn', 'btn-secondary'], GlobalShortcutKeys.Close),
    ] as IConfirmationButton[];
