import { NgModule } from '@angular/core';
import { provideTransloco, TRANSLOCO_LOADER, TranslocoModule } from '@jsverse/transloco';
import { GetLangParams, provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { TranslationPipe } from './pipes/translation.pipe';
import { TranslationService } from './services/translation.service';
import { TranslocoHttpLoader } from './transloco-http-loader';

export function getLangFn({ cachedLang, browserLang, defaultLang }: GetLangParams): string {
    if (cachedLang && cachedLang != 'null') {
        return cachedLang;
    } else if (browserLang) {
        return browserLang;
    }

    return defaultLang;
}

@NgModule({
    declarations: [TranslationPipe],
    exports: [TranslocoModule, TranslationPipe],
    providers: [
        TranslationService,
        {
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader,
        },
        provideTranslocoPersistLang({
            getLangFn,
            storage: {
                useValue: localStorage,
            },
        }),
        provideTransloco({
            config: {
                availableLangs: ['us', 'de'],
                defaultLang: 'us',
                fallbackLang: 'us',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
    ],
})
export class TranslocoRootModule {}
