import { DeviceStatus } from '@api/model/deviceStatus';
import languageDictionary from '../../transloco/default';
import { NestedPropertyOf } from '../../transloco/types/nested-key-of.type';

export class Breadcrumb {
    constructor(
        public text: NestedPropertyOf<typeof languageDictionary> | string,
        public route?: string,
        public deviceStatus: DeviceStatus = null
    ) {}
}
