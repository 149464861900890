import { AfterViewInit, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ReferencedEntity } from '@api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';
import { getRandomId } from '../../extensions/string.extensions';
import { ShortcutKeysService } from '../../services/shortcutKeys/shortcutKeys.service';
import { TranslationService } from '../../transloco/services/translation.service';
import { IConfirmationButton } from './confirmation-button';
import { referenceTypes, referenceWarnings } from './referenced-entities-repository';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements AfterViewInit, OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();
    private timerSubscription: Subscription = new Subscription();
    private translationService = inject(TranslationService);

    public title: string;
    public message: string;
    public deleteReferencedEntities: ReferencedEntity[];
    public isWarningDialog = false;
    public confirmationButtons: IConfirmationButton[];
    public loaderId = 'confirmation-dialog-loader_';

    public set isLoading$(value$: Observable<boolean>) {
        this.subscription.add(
            value$.subscribe((value) => {
                if (value) {
                    this.loaderService.startLoader(this.loaderId);
                    return;
                }

                this.loaderService.stopLoader(this.loaderId);
            })
        );
    }

    constructor(
        private shortcutKeysService: ShortcutKeysService,
        public activeModal: NgbActiveModal,
        private loaderService: NgxUiLoaderService
    ) {}

    public ngOnInit(): void {
        this.loaderId += getRandomId();
    }

    public ngAfterViewInit(): void {
        this.setShortcutKeys();
    }

    public ngOnDestroy(): void {
        this.shortcutKeysService.unregisterShortcuts(this.translationService.translate('confirmationModal.confirmationDialogHelpLabel'));
        this.subscription.unsubscribe();
        this.timerSubscription.unsubscribe();
    }

    public getReferencedEntityName(entityType: string) {
        const foundEntity = referenceTypes.find((entity) => entity.id === entityType);
        return this.translationService.translate(foundEntity?.name);
    }

    public getReferencedEntityWarnings(warnings: Array<string>) {
        const warningList = [] as Array<string>;
        warnings?.forEach((warning) => {
            const foundEntity = referenceWarnings.find((entity) => entity.id === warning);
            warningList.push(this.translationService.translate(foundEntity.name));
        });

        return warningList;
    }

    public displayReferencedEntities(message: string, referencedEntities: ReferencedEntity[], clickButtonResult = 'force-delete') {
        this.confirmationButtons[0].title = this.translationService.translate('actions.deleteAll');
        this.confirmationButtons[0].result = clickButtonResult;
        this.confirmationButtons[0].setDelay(5);

        this.message = message;
        this.deleteReferencedEntities = referencedEntities;
    }

    private setShortcutKeys() {
        const shortcuts = [];
        if (this.confirmationButtons) {
            const buttons = this.confirmationButtons?.filter((entity) => entity.shortcutKey != null);

            for (const confirmationButton of buttons) {
                const shortcut = this.shortcutKeysService.registerShortcut(
                    confirmationButton.shortcutKey,
                    () => {
                        if (Number(confirmationButton.remainingSeconds) === 0 && !confirmationButton.disabled) {
                            confirmationButton.click();
                        }
                    },
                    this.translationService.translate('confirmationModal.confirmationDialogHelpLabel'),
                    confirmationButton.title
                );

                shortcuts.push(shortcut);
            }
        }
        this.shortcutKeysService.registerShortcuts(shortcuts);
    }
}
