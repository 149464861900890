import { Component, EventEmitter, inject, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/shared/transloco/services/translation.service';
import { notificationSeverity } from '../notifications.repository';
import { notificationType } from './../notifications.repository';
import { IFilterCriterias } from './filter-criterias.interface';

@Component({
    selector: 'app-notifications-filter',
    templateUrl: './notifications-filter.component.html',
    styleUrls: ['./notifications-filter.component.scss'],
})
export class NotificationsFilterComponent implements OnInit, OnDestroy {
    private translationService = inject(TranslationService);
    private fb = inject(UntypedFormBuilder);
    private subscription = new Subscription();

    @Output() public filterChanged = new EventEmitter<IFilterCriterias>();
    public form: UntypedFormGroup;
    public notificationSeverity = signal([]);
    public notificationType = signal([]);
    public selectedType: string[] = [];

    constructor() {
        this.form = this.fb.group({
            notificationOwner: null,
            notificationSeverity: null,
            notificationType: null,
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnInit() {
        this.subscription.add(
            this.translationService.afterLanguageChanged$.subscribe(() => {
                this.notificationSeverity.set(notificationSeverity.map((severity) => ({ id: severity.id, name: this.translationService.translate(severity.name) })));
                this.notificationType.set(notificationType.map((type) => ({ id: type.id, name: this.translationService.translate(type.name) })));
            })
        );

        this.form.reset();
    }

    public onFilterChanged() {
        const formValues = this.form.value;
        this.filterChanged.emit(formValues);
    }
}
