export enum GlobalShortcutKeys {
    Create = 'cmd + i',
    Edit = 'cmd + e',
    Close = 'escape',
    SaveAndClose = 'cmd + enter',
    Save = 'cmd + s',
    FullScreen = 'cmd + f11',
    Copy = 'cmd + c',
    Delete = 'del',
    Help = 'f1',
    StartTour = 'f10',
    CreateInput = 'cmd + i',
    CreateOutput = 'cmd + o',
    Next = 'enter',
    AIAssistant = 'cmd + space',
    Ok = 'enter',
}
