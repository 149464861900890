import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, tap } from 'rxjs';
import { GlobalShortcutKeys } from 'src/app/shared/enumerations/global.shortcutKeys';
import { TranslationService } from 'src/app/shared/transloco/services/translation.service';
import { TranslocoRootModule } from 'src/app/shared/transloco/transloco-root.module';
import { ShortcutKeysService } from '../../shortcutKeys/shortcutKeys.service';

@Component({
    selector: 'app-ai-assistant-dialog',
    standalone: true,
    imports: [NgbModalModule, CommonModule, TranslocoRootModule],
    templateUrl: './ai-assistant-dialog.component.html',
    styleUrl: './ai-assistant-dialog.component.scss',
})
export class AiAssistantDialogComponent implements AfterViewInit, OnDestroy {
    private subscription = new Subscription();
    private translationService = inject(TranslationService);
    private shortcutKeysService = inject(ShortcutKeysService);
    @ViewChild('inputField') private inputField: ElementRef<HTMLTextAreaElement>;

    public activeModal = inject(NgbActiveModal);

    public ngAfterViewInit(): void {
        this.inputField.nativeElement.focus();
        this.registerShortcutKeys();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public close(): void {
        const value = this.inputField.nativeElement.value;
        this.activeModal.close(value);
    }

    private registerShortcutKeys() {
        this.subscription.add(
            this.translationService.afterLanguageChanged$
                .pipe(
                    tap(() => {
                        const aiAssistantLabel = this.translationService.translate('shortcutKeys.aiAssistant');
                        const shortcuts = [this.shortcutKeysService.registerShortcut(GlobalShortcutKeys.Ok, () => this.close(), aiAssistantLabel, this.translationService.translate('actions.ok'))];

                        this.shortcutKeysService.unregisterShortcuts(aiAssistantLabel);
                        this.shortcutKeysService.registerShortcuts(shortcuts);
                    })
                )
                .subscribe()
        );
    }
}
