<div class="not-found">
    <div class="header">
        <img src="assets/images/achtbytes-logo.png" height="35" class="mb-1 me-2" />
        <span class="header-title"></span>

        <ul class="menu">
            @if ((isUserLoggedIn$ | async) === false) {
                <li [ngbTooltip]="'actions.login' | translation | async">
                    <a (click)="login()">
                        <li class="fas fa-sign-in-alt fa-lg"></li>
                        <span>{{ 'actions.login' | translation | async }}</span>
                    </a>
                </li>
            }

            @if (isUserLoggedIn$ | async) {
                <li [ngbTooltip]="'actions.logout' | translation | async">
                    <a (click)="logout()">
                        <li class="fas fa-sign-out-alt fa-lg"></li>
                        <span>{{ username$ | async }}</span>
                    </a>
                </li>
            }
        </ul>
    </div>

    <div class="content text-center default-icon-color">
        <div class="vertical-centering default-icon-color">
            <i class="fas fa-info-circle fa-7x"></i>
            <div class="no-records-message">{{ 'notFound.tenantNotFound' | translation | async }}.</div>
            <div class="no-records-message-detail">{{ 'notFound.contactAdministrator' | translation | async }}</div>
        </div>
    </div>
</div>
