import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable, inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subscription, from, fromEvent, iif, of } from 'rxjs';
import { RetryConfig, catchError, delay, filter, mergeMap, pairwise, retry, take, tap } from 'rxjs/operators';
import { TranslationService } from '../transloco/services/translation.service';
import { LoggingService } from './logging.service';
import { NotificationsService } from './notifications-service/notifications.service';

@Injectable({ providedIn: 'root' })
export class PushNotificationsService {
    private readonly inactiveTimeout = 10 * 60 * 1000;
    private pushConnection?: HubConnection = null;
    private dataReceiverHandlers: Record<string, (data: object) => Observable<object>> = {};
    private hubName = 'tenant';
    private subscription = new Subscription();
    private translationService = inject(TranslationService);

    public inactiveTimestamp: number;
    public reactivationCallbacks: Record<string, () => void> = {};
    public hubConnectionState$ = new BehaviorSubject<HubConnectionState>(HubConnectionState.Disconnected);

    constructor(
        @Inject(APP_BASE_HREF) private baseHref: string,
        private authService: MsalService,
        private store: Store,
        private notificationsService: NotificationsService,
        private logging: LoggingService
    ) {
        this.subscribeDocumentVisibilityChange();
    }

    private get hasInactivityTimeExpired(): boolean {
        if (!this.inactiveTimestamp) {
            return false;
        }

        const inactiveDuration = Date.now() - this.inactiveTimestamp;
        return inactiveDuration >= this.inactiveTimeout;
    }

    public async start() {
        if (this.isConnected()) {
            return;
        }

        const url = `${this.baseHref}/hubs/${this.hubName}`;
        const accessToken = this.getAccessToken();

        if (!accessToken) {
            return;
        }

        this.pushConnection = new HubConnectionBuilder()
            .withUrl(url, { accessTokenFactory: () => this.getAccessToken() })
            .withAutomaticReconnect()
            .build();

        this.pushConnection.onreconnected(() => {
            this.hubConnectionState$.next(HubConnectionState.Connected);
        });

        this.pushConnection.onreconnecting((err) => {
            if (err != null) {
                this.hubConnectionState$.next(HubConnectionState.Reconnecting);
                throw err;
            }
        });

        this.subscribeConnectionState();

        await this.startInternal();
    }

    public async stop() {
        try {
            if (this.isConnected()) {
                return this.pushConnection.stop();
            }
        } catch (err) {
            throw err;
        }
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public async send(methodName: string, ...args: any[]) {
        // execute one time once hub is connected
        this.subscription.add(
            this.hubConnectionState$.pipe(filter((state) => state === HubConnectionState.Connected)).subscribe(() => {
                try {
                    return this.pushConnection.send(methodName, ...args);
                } catch (err) {
                    throw err;
                }
            })
        );
    }

    public async on<T>(methodName: string, id: string, TCreator: new (data: object, id?: string) => T, reactivationCallbacks?: () => void) {
        // execute one time once hub is connected
        this.subscription.add(
            this.hubConnectionState$
                .pipe(
                    filter((state) => state === HubConnectionState.Connected),
                    tap(async () => {
                        const onDataReceiverHandler = (data: object) => this.store.dispatch(new TCreator(data, id));
                        const key = this.buildKey(methodName, id, TCreator);
                        const isDataReceiverHandlerExisting = this.isDataReceiverHandlerExisting(key, onDataReceiverHandler);
                        if (isDataReceiverHandlerExisting) {
                            return;
                        }

                        this.pushConnection.on(methodName, onDataReceiverHandler);

                        if (reactivationCallbacks != null) {
                            this.reactivationCallbacks[key] = reactivationCallbacks;
                        }
                    })
                )
                .subscribe()
        );
    }

    public async off<T>(methodName: string, id: string, TCreator: new (data: object, id?: string) => T) {
        // execute one time once hub is connected
        this.subscription.add(
            this.hubConnectionState$
                .pipe(
                    filter((state) => state === HubConnectionState.Connected),
                    take(1),
                    tap(() => {
                        const key = this.buildKey(methodName, id, TCreator);
                        const dataReceiverHandler = this.dataReceiverHandlers[key];
                        if (dataReceiverHandler) {
                            this.pushConnection.off(methodName, dataReceiverHandler);
                            delete this.dataReceiverHandlers[key];
                        }

                        if (this.reactivationCallbacks?.[key]) {
                            delete this.reactivationCallbacks[key];
                        }
                    })
                )
                .subscribe()
        );
    }

    private isDataReceiverHandlerExisting<T>(key: string, method: (data: object) => Observable<object>) {
        if (this.dataReceiverHandlers[key]) {
            return true;
        }

        this.dataReceiverHandlers[key] = method;

        return false;
    }

    private buildKey<T>(methodName: string, id: string, TCreator: new (data: object, id?: string) => T) {
        return `${methodName}_${id}_${TCreator.prototype.constructor.type.replaceAll(' ', '')}`;
    }

    private isConnected() {
        return this.pushConnection != null && this.pushConnection.state !== HubConnectionState.Disconnected;
    }

    private async startInternal() {
        this.subscription.add(
            from(this.pushConnection.start())
                .pipe(
                    tap(() => {
                        this.logging.info('Hub connection started');

                        if (this.pushConnection.state === HubConnectionState.Connected) {
                            this.hubConnectionState$.next(HubConnectionState.Connected);
                        }
                    }),
                    catchError((err) => {
                        this.logging.error(err);
                        throw new Error(err);
                    }),
                    retry({ count: 10, delay: 5000, resetOnSuccess: true } as RetryConfig),
                    catchError(() => {
                        this.notificationsService.error(this.translationService.translate('pushNotification.realtimeUpdateNotAvailable'));
                        return of();
                    })
                )
                .subscribe()
        );
    }

    private getAccessToken(): string {
        const activeAccount = this.authService.instance.getActiveAccount() ?? this.authService.instance.getAllAccounts()?.[0];
        return activeAccount?.idToken;
    }

    private subscribeConnectionState() {
        this.subscription.add(
            this.hubConnectionState$
                .pipe(
                    mergeMap((state) => iif(() => state === HubConnectionState.Reconnecting, of(state).pipe(delay(5000)), of(state))),
                    pairwise(),
                    tap(([previousState, currentState]) => {
                        if (previousState === HubConnectionState.Reconnecting && currentState === HubConnectionState.Connected) {
                            this.notificationsService.success(this.translationService.translate('pushNotification.realtimeUpdateAvailable'));
                            return;
                        }

                        if (currentState === HubConnectionState.Reconnecting && this.pushConnection.state !== HubConnectionState.Connected) {
                            this.notificationsService.error(this.translationService.translate('pushNotification.realtimeUpdateNotAvailable'));
                        }
                    })
                )
                .subscribe()
        );
    }

    private subscribeDocumentVisibilityChange() {
        this.subscription.add(
            fromEvent(document, 'visibilitychange')
                .pipe(
                    tap(() => {
                        if (document.visibilityState === 'hidden') {
                            this.inactiveTimestamp = Date.now();
                        }
                    }),
                    filter(() => document.visibilityState === 'visible' && Object.values(this.reactivationCallbacks ?? {}).length && this.hasInactivityTimeExpired),
                    mergeMap(() => Object.values(this.reactivationCallbacks)),
                    filter((reactivationCallbacks) => reactivationCallbacks != null),
                    tap((reactivationCallbacks) => reactivationCallbacks())
                )
                .subscribe()
        );
    }
}
