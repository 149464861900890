import { Injectable } from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BreadcrumbsService } from '../services/breadcrumbs/breadcrumbs.service';
import { NotificationsService } from '../services/notifications-service/notifications.service';
import { PushNotificationsService } from '../services/push-notifications.service';
import { ShortcutKeysService } from '../services/shortcutKeys/shortcutKeys.service';
import { TranslationService } from '../transloco/services/translation.service';
import { ConfirmationModalService } from './confirmation-modal/confirmation-modal.service';

@Injectable({ providedIn: 'root' })
export class ComponentBaseService {
    constructor(
        public store: Store,
        public loaderService: NgxUiLoaderService,
        public actions$: Actions,
        public breadcrumbs: BreadcrumbsService,
        public shortcutKeysService: ShortcutKeysService,
        public pushNotificationsService: PushNotificationsService,
        public notificationsService: NotificationsService,
        public confirmationModalService: ConfirmationModalService,
        public translationService: TranslationService
    ) {}
}
