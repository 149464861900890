import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { TENANT_ID } from 'src/app/authorization/authorization-factories';
import { HelpGuideService } from '../../services/help-guide.service';
import { Login, Logout } from '../../state/application.actions';
import { ApplicationState } from '../../state/application.state';
import { ComponentBaseDirective } from '../component.base';
import { ComponentBaseService } from '../component.base.service';

@Component({
    selector: 'app-portal-header',
    templateUrl: './portal-header.component.html',
    styleUrls: ['./portal-header.component.scss'],
})
export class PortalHeaderComponent extends ComponentBaseDirective {
    public language$ = this.translationService.languageChanges$;
    public gettingStartedLink$ = this.language$.pipe(map((language) => `/documentation/#/${language}/QuickStart`));

    public isNotificationsShown = false;
    public imageWasLoaded = false;

    @Input() public displaySettings = true;

    @ViewChild('notificationArea') public notificationArea: ElementRef;

    @Select(ApplicationState.isUserLoggedIn) public isUserLoggedIn$: Observable<boolean>;
    @Select(ApplicationState.currentUserName) public username$: Observable<string>;
    @Select(ApplicationState.hasAcceptedDisclaimer) public hasAcceptedDisclaimer$: Observable<boolean>;

    public get hasTour() {
        return this.helpGuideService.hasTour;
    }

    constructor(
        public componentBaseService: ComponentBaseService,
        public readonly store: Store,
        private helpGuideService: HelpGuideService,
        @Inject(TENANT_ID) public tenantId: string
    ) {
        super(componentBaseService);
    }

    public login() {
        this.store.dispatch(new Login());
    }

    public logout() {
        this.store.dispatch(new Logout());
    }

    public showShortcutKeys() {
        this.toggleShortcutHelpComponent();
    }

    public startTour() {
        this.helpGuideService.start();
    }

    public showNotifications() {
        this.isNotificationsShown = !this.isNotificationsShown;
    }

    public hideNotifications() {
        this.isNotificationsShown = false;
    }

    public imageLoaded() {
        this.imageWasLoaded = true;
    }

    public changeLanguage() {
        const languages = this.translationService.getAvailableLanguages();
        const index = languages.findIndex((language) => language === this.translationService.getActiveLanguage());
        const nextLanguage = languages[index + 1] ?? languages[0];
        this.translationService.setActiveLanguage(nextLanguage as string);
    }
}
