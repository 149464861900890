<app-portal-header></app-portal-header>

<div class="content disclaimer-container">
    <h3>{{ 'disclaimer.title' | translation | async }}</h3>
    <div class="disclaimer-message">{{ 'disclaimer.message' | translation | async }}</div>
    <div class="mt-3 mb-3"><input type="checkbox" [checked]="hasAccepted" (click)="acceptDisclaimer()" /> {{ 'disclaimer.giveConsent' | translation | async }}</div>
    <hr />
    <div>
        <button type="button" class="btn btn-primary" (click)="confirmAcceptedDisclaimer()" [disabled]="!hasAccepted">{{ 'actions.save' | translation | async }}</button>
    </div>
</div>
