import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation } from '@jsverse/transloco';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader {
    constructor(private http: HttpClient) {}

    public getTranslation(language: string): Observable<Translation> {
        const translationFile$ = this.http.get<Translation>(`/assets/i18n/${language}.json`);
        return translationFile$;
    }
}
