@if (!isIframe && (authorizationService.isLoginInProgress$ | async) === false && (displayPage$ | async) == true) {
    @if ((authorizationService.hasPermissions$ | async) === false || (authorizationService.hasAcceptedSecureEnvironmentDisclaimer$ | async) === false) {
        <router-outlet></router-outlet>
    } @else {
        @if ((authorizationService.hasPermissions$ | async) && (authorizationService.hasAcceptedSecureEnvironmentDisclaimer$ | async)) {
            <app-nav-side-bar>
                <router-outlet></router-outlet>
            </app-nav-side-bar>
        }
    }
    <ng-keyboard-shortcuts-stego></ng-keyboard-shortcuts-stego>
    <ng-keyboard-shortcuts-help-stego [title]="'header.keyboardShortcuts' | translation | async"></ng-keyboard-shortcuts-help-stego>
}

<ngx-ui-loader [loaderId]="'master'"></ngx-ui-loader>
