import { IGeneralItem } from '../../interfaces/general-item';

export const referenceTypes: IGeneralItem[] = [
    { id: 'Asset', name: 'assets.name.singular' },
    { id: 'TenantWorkflowModule', name: 'workflows.modules.name.singular' },
    { id: 'AlertAction', name: 'alerts.alertAction.name' },
    { id: 'AlertCondition', name: 'alerts.alertCondition.name' },
    { id: 'TenantDashboardWidget', name: 'dashboards.widgets.name' },
    { id: 'DeviceWorkflow', name: 'workflows.overview.name.singular' },
    { id: 'DeviceAnalytics', name: 'analytics.name' },
];

export const referenceWarnings: IGeneralItem[] = [
    { id: 'DeviceIsOffline', name: 'defaults.notifications.deviceIsOffline' },
    { id: 'Unauthorized', name: 'defaults.notifications.unauthorized' },
];
