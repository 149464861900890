import { inject, Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import languageDictionary from '../default';
import { TranslationService } from '../services/translation.service';
import { NestedPropertyOf } from '../types/nested-key-of.type';

@Pipe({
    name: 'translation',
})
export class TranslationPipe implements PipeTransform {
    private translationService = inject(TranslationService);

    public transform(dictionaryKey: NestedPropertyOf<typeof languageDictionary>, params?: { [key: string]: string }): Observable<string> {
        return this.translationService.afterLanguageChanged$.pipe(
            map(() => {
                const translation = this.translationService.translate(dictionaryKey, params);
                return translation;
            })
        );
    }
}
