/* TODO: Fix me */
/* eslint max-lines: 0 */
import { ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PageResult } from '@api';
import { ActionType, Actions, Store, ofActionCompleted, ofActionDispatched, ofActionSuccessful } from '@ngxs/store';
import { ToastContainerDirective } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { paginationCalculator } from 'pagination-calculator';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ofActionErroredDetail } from '../error-handling';
import { ILocalize } from '../interfaces/localize.interface';
import { BreadcrumbsService } from '../services/breadcrumbs/breadcrumbs.service';
import { NotificationsService } from '../services/notifications-service/notifications.service';
import { PushNotificationsService } from '../services/push-notifications.service';
import { ShortcutKeysService } from '../services/shortcutKeys/shortcutKeys.service';
import { TranslationService } from '../transloco/services/translation.service';
import { RealTimeRegistrationName } from '../types/real-time-registration-name.type';
import { ComponentBaseService } from './component.base.service';
import { ConfirmationModalService } from './confirmation-modal/confirmation-modal.service';
import { KeyboardShortcutsHelpComponent } from './ng-keyboard-shortcuts-stego/ng-keyboard-shortcuts-help.component';
import { ShortcutInput } from './ng-keyboard-shortcuts-stego/ng-keyboard-shortcuts.interfaces';
import { KeyboardShortcutsService } from './ng-keyboard-shortcuts-stego/ng-keyboard-shortcuts.service';

export interface IOnRouteParamsChanged {
    routeParamsChanged(params: Params): void;
}

export interface IOnRouteQueryParamsChanged {
    routeQueryParamsChanged(params: Params): void;
}

export interface IAllowRealTimeUpdates {
    startRealTimeUpdates(): void;
    stopRealTimeUpdates(): void;
}

@Directive()
export abstract class ComponentBaseDirective implements OnDestroy {
    private static shortcutHelpComponentRef: KeyboardShortcutsHelpComponent;
    private shortcutKeysService: ShortcutKeysService;
    private shortcutGroupName: string;
    private loaderIds: string[] = [];

    protected subscription = new Subscription();
    protected loaderService: NgxUiLoaderService;
    protected actions$: Actions;
    protected breadcrumbs: BreadcrumbsService;
    protected notificationsService: NotificationsService;
    protected pushNotificationsService: PushNotificationsService;
    protected confirmationModalService: ConfirmationModalService;
    protected translationService: TranslationService;

    public store: Store;

    constructor(componentBaseService: ComponentBaseService) {
        this.loaderService = componentBaseService.loaderService;
        this.actions$ = componentBaseService.actions$;
        this.store = componentBaseService.store;
        this.breadcrumbs = componentBaseService.breadcrumbs;
        this.shortcutKeysService = componentBaseService.shortcutKeysService;
        this.pushNotificationsService = componentBaseService.pushNotificationsService;
        this.notificationsService = componentBaseService.notificationsService;
        this.confirmationModalService = componentBaseService.confirmationModalService;
        this.translationService = componentBaseService.translationService;
    }

    public ngOnDestroy() {
        this.shortcutKeysService.unregisterShortcuts(this.shortcutGroupName);
        this.notificationsService.notificationContainer = null;
        this.unregisterLoaders();

        this.stopRealTimeUpdatesInternal();

        this.subscription.unsubscribe();
    }

    public toggleShortcutHelpComponent() {
        ComponentBaseDirective.shortcutHelpComponentRef.toggle();
    }

    public registerKeyboardService(keyboardShortcutsService: KeyboardShortcutsService, changeDetectorRef: ChangeDetectorRef) {
        this.shortcutKeysService.registerShortcutHelp(keyboardShortcutsService, changeDetectorRef);
    }

    public registerShortcutHelp(shortcutHelpComponentRef: KeyboardShortcutsHelpComponent) {
        ComponentBaseDirective.shortcutHelpComponentRef = shortcutHelpComponentRef;
    }

    public unregisterShortcuts(shortcutGroupName: string) {
        this.shortcutKeysService.unregisterShortcuts(shortcutGroupName);
    }

    public registerShortcut(shortcut: string, method: () => void, label?: string, description?: string, target?: HTMLElement) {
        return this.shortcutKeysService.registerShortcut(shortcut, method, label, description, target);
    }

    public registerComponentShortcutKeys(shortcuts: ShortcutInput[], shortcutGroupName: string) {
        this.shortcutKeysService.unregisterShortcuts(this.shortcutGroupName);

        this.shortcutGroupName = shortcutGroupName;
        this.shortcutKeysService.registerShortcuts(shortcuts);
    }

    public getPaginationDetails<T extends { pages?: PageResult }>(itemsResult: T): string {
        const result = paginationCalculator({
            total: itemsResult?.pages?.totalItems,
            current: itemsResult?.pages?.currentPage,
            pageSize: itemsResult?.pages?.size,
            pageLimit: itemsResult?.pages?.size,
        });

        return this.translationService.translate('defaults.pagination', {
            showingStart: result.showingStart,
            showingEnd: result.showingEnd,
            total: result.total,
        });
    }

    protected registerNotificationContainer(container: ToastContainerDirective) {
        this.notificationsService.notificationContainer = container;
    }

    protected addRouteParamsChanged(route: ActivatedRoute, onlyOnce = true) {
        const routeParams = route.params;
        if (onlyOnce) {
            routeParams.pipe(take(1));
        }
        this.subscribe(routeParams, (params) => {
            const self = this as unknown as IOnRouteParamsChanged;
            if (self.routeParamsChanged != null) {
                self.routeParamsChanged(params);
            }
        });
    }

    protected addRouteQueryParamsChanged(route: ActivatedRoute, onlyOnce = true) {
        const routeParams = route.queryParams;
        if (onlyOnce) {
            routeParams.pipe(take(1));
        }
        this.subscribe(routeParams, (params) => {
            const self = this as unknown as IOnRouteQueryParamsChanged;
            if (self.routeQueryParamsChanged != null) {
                self.routeQueryParamsChanged(params);
            }
        });
    }

    protected addLoader(loaderName: string, ...allowedTypes: ActionType[]) {
        this.loaderIds.push(loaderName);
        const dispatchedActions = [] as Array<object>;
        this.ofActionDispatchedInternal(() => this.loaderService.startLoader(loaderName), allowedTypes, dispatchedActions);
        this.ofActionCompletedInternal(() => this.loaderService.stopLoader(loaderName), allowedTypes, dispatchedActions);
    }

    protected addBackgroundLoader(loaderName: string, ...allowedTypes: ActionType[]) {
        this.ofActionDispatched(() => this.loaderService.startBackgroundLoader(loaderName), ...allowedTypes);
        this.ofActionCompleted(() => this.loaderService.stopBackgroundLoader(loaderName), ...allowedTypes);
    }

    protected addBreadcrumb(callback: () => void, ...allowedTypes: ActionType[]) {
        this.ofActionCompleted(() => callback(), ...allowedTypes);
    }

    protected addSuccessNotification(message: string, ...allowedTypes: ActionType[]): void;
    protected addSuccessNotification(message: string, title: string, ...allowedTypes: ActionType[]): void;
    protected addSuccessNotification(message: string, title: string | ActionType, ...allowedTypes: ActionType[]): void {
        if (typeof title === 'string') {
            this.ofActionSuccessful(() => this.notificationsService.success(message, title), ...allowedTypes);
        } else {
            this.ofActionSuccessful(() => this.notificationsService.success(message), title, ...allowedTypes);
        }
    }

    protected addSuccessNotificationCallback(text: string, callback: () => void, ...allowedTypes: ActionType[]) {
        this.ofActionSuccessful(
            () => {
                this.notificationsService.success(text);
                callback();
            },
            ...allowedTypes
        );
    }

    protected addSuccessNotificationWithLanguageKey(localize: ILocalize, ...allowedTypes: ActionType[]): void;
    protected addSuccessNotificationWithLanguageKey(localize: ILocalize, title: string, ...allowedTypes: ActionType[]): void;
    protected addSuccessNotificationWithLanguageKey(localize: ILocalize, title: string | ActionType, ...allowedTypes: ActionType[]): void {
        if (typeof title === 'string') {
            this.ofActionSuccessful(() => this.notificationsService.success(this.translationService.translate(localize.key, localize.parameters), title), ...allowedTypes);
        } else {
            this.ofActionSuccessful(() => this.notificationsService.success(this.translationService.translate(localize.key, localize.parameters)), title, ...allowedTypes);
        }
    }

    protected addSuccessNotificationCallbackWithLanguageKey(localize: ILocalize, callback: () => void, ...allowedTypes: ActionType[]) {
        this.ofActionSuccessful(
            () => {
                this.notificationsService.success(this.translationService.translate(localize.key, localize.parameters));

                callback();
            },
            ...allowedTypes
        );
    }

    protected addErrorNotificationWithLanguageKey(localize: ILocalize, ...allowedTypes: ActionType[]) {
        this.ofActionErrored((result) => this.notificationsService.error(this.translationService.translate(localize.key, localize.parameters), result.error), ...allowedTypes);
    }

    protected addErrorNotification(text: string, ...allowedTypes: ActionType[]) {
        this.ofActionErrored((result) => this.notificationsService.error(text, result.error), ...allowedTypes);
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    protected ofActionSuccessful(callback: (state?: any) => void, ...allowedTypes: ActionType[]) {
        this.subscribe(this.actions$.pipe(ofActionSuccessful(...allowedTypes)), (state) => callback(state));
    }

    protected ofActionCompleted(callback: () => void, ...allowedTypes: ActionType[]) {
        this.ofActionCompletedInternal(callback, allowedTypes);
    }

    private ofActionCompletedInternal(callback: () => void, allowedTypes: ActionType[], trackedActions: object[] = undefined) {
        this.subscribe(this.actions$.pipe(ofActionCompleted(...allowedTypes)), (a) => {
            if (trackedActions != null) {
                const idx = trackedActions.indexOf(a.action);
                const isTrackedAction = idx >= 0;

                if (!isTrackedAction) {
                    // if action tracking is activated.
                    return;
                }

                // remove completed action from tracking.
                trackedActions.splice(idx, 1);

                return trackedActions.length === 0 ? callback() : of();
            }

            return callback();
        });
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    protected ofActionErrored(callback: (result: any) => void, ...allowedTypes: ActionType[]) {
        this.subscribe(this.actions$.pipe(ofActionErroredDetail(...allowedTypes)), (result) => callback(result));
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    protected ofActionDispatched(callback: (actionContext: any) => void, ...allowedTypes: ActionType[]) {
        this.ofActionDispatchedInternal(callback, allowedTypes);
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    private ofActionDispatchedInternal(callback: (actionContext: any) => void, allowedTypes: ActionType[], trackedActions: object[] = undefined) {
        this.subscribe(this.actions$.pipe(ofActionDispatched(...allowedTypes)), (a) => {
            trackedActions && trackedActions.push(a);
            callback(a);
        });
    }

    protected subscribe<T>(observable: Observable<T>, callback: (result: T) => void) {
        if (observable == null) {
            return;
        }

        this.subscription.add(observable.subscribe(callback));
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    protected select<T>(selector: (state: any, ...states: any[]) => T, updateFunc: (newValue: T) => void) {
        this.subscription.add(this.store.select(selector).subscribe((newValue) => updateFunc(newValue)));
    }

    protected addLoadingObservable(...allowedTypes: ActionType[]) {
        const subject = new BehaviorSubject(false);

        this.ofActionDispatched(() => subject.next(true), ...allowedTypes);
        this.ofActionCompleted(() => subject.next(false), ...allowedTypes);

        return subject.asObservable();
    }

    protected async stopRealTime(
        value: string,
        registerName: string,
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        action: any
    ) {
        await this.unregisterRealTime(registerName, value);
        this.unreceiveRealTime(registerName, value, action);
    }

    protected async stopRealTimeValue({
        value,
        registerName,
        receiveName,
        action,
    }: {
        value: string;
        registerName: string;
        receiveName: string;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        action: any;
    }) {
        await this.unregisterRealTime(registerName, value);
        this.unreceiveRealTime(receiveName, value, action);
    }

    protected async startRealTimeValue({
        value,
        registerName,
        receiveName,
        action,
    }: {
        value: string;
        registerName: string;
        receiveName: string;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        action: any;
    }) {
        await this.registerRealTime(registerName, value);
        this.receiveRealTime(receiveName, value, action);
    }

    protected async startRealTime(
        value: string,
        registerName: RealTimeRegistrationName,
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        action: any,
        reactivationCallbacks?: () => void
    ) {
        await this.registerRealTime(registerName, value);
        this.receiveRealTime(registerName, value, action, reactivationCallbacks);
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    protected async registerRealTime(methodName: string, ...args: string[]) {
        const registrationMethod = `register${this.capitalize(methodName)}`;
        await this.pushNotificationsService.send(registrationMethod, ...args);
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    protected async unregisterRealTime(methodName: string, ...args: string[]) {
        const registrationMethod = `unregister${this.capitalize(methodName)}`;
        await this.pushNotificationsService.send(registrationMethod, ...args);
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    protected receiveRealTime<T>(methodName: string, id: string, action: new (data: object, id?: string) => T, reactivationCallbacks?: () => void) {
        const receiverMethod = `receive${this.capitalize(methodName)}_${id}`;
        this.pushNotificationsService.on(receiverMethod, id, action, reactivationCallbacks);
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    protected unreceiveRealTime<T>(methodName: string, id: string, action: new (data: object, id?: string) => T) {
        const receiverMethod = `receive${this.capitalize(methodName)}_${id}`;
        this.pushNotificationsService.off(receiverMethod, id, action);
    }

    private async stopRealTimeUpdatesInternal() {
        const self = this as unknown as IAllowRealTimeUpdates;
        if (self.stopRealTimeUpdates != null) {
            await self.stopRealTimeUpdates();
        }
    }

    private unregisterLoaders() {
        for (const loaderId of this.loaderIds) {
            const loader = this.loaderService.getLoader(loaderId);
            if (loader) {
                this.loaderService.destroyLoaderData(loaderId);
            }
        }
    }

    private capitalize(name: string) {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
}
