<app-portal-header></app-portal-header>

<div>
    <ng-sidebar-container class="sidebar-container">
        <ng-sidebar
            (openedChange)="toggled($event)"
            [opened]="isCollapsed$ | async"
            mode="push"
            position="left"
            dock="true"
            dockedSize="50px"
            autoCollapseWidth="900"
            animate="true"
            autoFocus="false"
            [ariaLabel]="'defaults.platformName' | translation | async"
            [keyClose]="false"
        >
            <div class="togglebar" [class.sidebar-closed]="!opened" (click)="toggleSideBar()">
                <button class="sidebar-toggle">{{ 'sidebar.toggleSidebar' | translation | async }}</button>
                <span class="fw-bold mx-2 lh-1"> {{ 'defaults.platformName' | translation | async }} </span>
            </div>
            <ul class="nav-sidebar">
                @for (navItem of navSideBarItems; track navItem) {
                    @if (checkPermissionTree(navItem)) {
                        <li
                            [ngbTooltip]="navItem.tooltip | translation | async"
                            [disableTooltip]="opened || navItem.subItems?.length > 0"
                            placement="right"
                            class="nav-item"
                            [class.sidebar-closed]="!opened"
                            [routerLinkActive]="navItem.subItems?.length === 0 ? 'link-active' : 'parent-link-active'"
                            [routerLinkActiveOptions]="navItem.routerLinkOptions"
                        >
                            @if (navItem.subItems?.length === 0) {
                                <div (mouseenter)="closeDropdown()" (click)="closeAllSubMenus()">
                                    <a id="nav-item-{{ navItem.id }}" class="nav-link" [routerLink]="navItem.routerLink">
                                        <i class="fas fa-lg" [class]="navItem.icon"></i>
                                        <span class="ps-2">{{ navItem.displayName | translation | async }}</span></a
                                    >
                                </div>
                            } @else {
                                <div ngbDropdown appNgbDropdownOnHover [dropdowns]="dropdowns" [isExpanded]="showExpand(navItem.IsExpanded)" placement="right-top" container="body" display="dynamic" (click)="toggleExpanded(navItem)">
                                    <a class="nav-link w-100" ngbDropdownToggle>
                                        <i class="fas fa-lg" [class]="navItem.icon"></i>
                                        <span class="ps-2">{{ navItem.displayName | translation | async }}</span>
                                    </a>
                                    <div ngbDropdownMenu>
                                        <h6 class="dropdown-header">{{ navItem.displayName | translation | async }}</h6>
                                        @for (subItem of navItem.subItems; track subItem) {
                                            <a
                                                *appHasPermission="subItem.permission"
                                                ngbDropdownItem
                                                id="nav-dropdown-item-{{ navItem.id }}-{{ subItem.id }}"
                                                class="nav-link py-2 px-3"
                                                (click)="toggleExpanded(navItem, true)"
                                                [routerLink]="subItem.routerLink"
                                            >
                                                <i class="fas" [class]="subItem.icon"></i>
                                                <span class="ps-3">{{ subItem.displayName | translation | async }}</span></a
                                            >
                                        }
                                    </div>
                                </div>
                                <ul class="nav-sidebar flex-column" [ngClass]="navItem.IsExpanded ? 'subnav-expanded' : 'subnav-collapsed'">
                                    @for (subItem of navItem.subItems; track subItem) {
                                        <li
                                            *appHasPermission="subItem.permission"
                                            [ngbTooltip]="subItem.tooltip | translation | async"
                                            [disableTooltip]="opened"
                                            placement="right"
                                            class="nav-item"
                                            [class.sidebar-closed]="!opened"
                                            routerLinkActive="child-link-active"
                                            [routerLinkActiveOptions]="subItem.routerLinkOptions"
                                            [routerLink]="subItem?.routerLink"
                                        >
                                            <a id="nav-item-{{ navItem.id }}-{{ subItem.id }}" class="nav-link child-link text-truncate">
                                                <i class="fas fa-lg" [class]="subItem.icon"></i>
                                                <span class="ps-2">{{ subItem.displayName | translation | async }}</span></a
                                            >
                                        </li>
                                    }
                                </ul>
                            }
                        </li>
                    }
                }
            </ul>

            <div class="version-info">{{ version.environment }} {{ 'sidebar.version' | translation | async }} {{ version.number }}</div>
        </ng-sidebar>

        <div class="sidebar-content-container" ng-sidebar-content (mouseenter)="closeDropdown()">
            <section class="sidebar-content flex-vertical-container">
                <div class="sidebar-content-header">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            @for (item of breadcrumbs.navigatedPath$ | async; track item) {
                                <li aria-current="page" class="breadcrumb-item" [class.active]="!item.route">
                                    @if (item.deviceStatus !== null) {
                                        <app-device-status [ngClass]="'device-status'" [status]="item.deviceStatus"> </app-device-status>
                                    }
                                    <a [routerLink]="item.route">{{ $any(item.text) | translation | async }} </a>
                                </li>
                            }
                        </ol>
                    </nav>
                </div>
                <ng-content></ng-content>
            </section>
        </div>
    </ng-sidebar-container>
</div>
