<h6>{{ 'notifications.title' | translation | async }}</h6>
<div class="notifications-operations text-primary">
    <a (click)="markAllAsRead()">{{ 'notifications.markAsRead' | translation | async }}</a>
    <span class="notifications-links-separator"></span>
    <a (click)="toggleFilterContainer()">{{ 'notifications.filter' | translation | async }}</a>
</div>

@if (isFilteringEnabled) {
    <app-notifications-filter (filterChanged)="filterNotifications($event)"> </app-notifications-filter>
}

<div class="mt-3" *ngLet="notifications$ | async as notifications">
    @if (notifications?.items?.length) {
        @for (notification of notifications?.items; track notification; let i = $index) {
            <div class="d-flex px-2" [class.bg-light]="i % 2">
                <div>
                    @switch (notification.severity) {
                        @case (TenantNotificationSeverity.NUMBER_2) {
                            <span [ngbTooltip]="'notifications.errorSeverity' | translation | async" class="notification-severity text-danger"><i class="fas fa-times-circle fa-lg"></i></span>
                        }
                        @case (TenantNotificationSeverity.NUMBER_1) {
                            <span [ngbTooltip]="'notifications.warningSeverity' | translation | async" class="notification-severity text-warning"><i class="fas fa-exclamation-circle fa-lg"></i></span>
                        }
                        @case (TenantNotificationSeverity.NUMBER_0) {
                            <span [ngbTooltip]="'notifications.informationSeverity' | translation | async" class="notification-severity text-info"><i class="fas fa-info-circle fa-lg"></i></span>
                        }
                    }
                </div>
                <div class="flex-grow-1">{{ getNotificationTypeTitle(notification.notificationType, notification.messageParameters) }}</div>
                <div class="">{{ notification.timestamp | localDate }}</div>
                <div class="ps-3">
                    <a (click)="readNotification(notification.id)" ngbTooltip="Mark as read" placement="left">
                        <i class="fas fa-times"></i>
                    </a>
                </div>
            </div>
            <div class="px-2 pt-2 pb-3 notification-text border-bottom" [class.bg-light]="i % 2">
                <span>{{ getNotificationTypeText(notification.notificationType, notification.messageParameters) }}</span>
                @if (notification.link != null) {
                    <a [routerLink]="notification.link" ngbTooltip="Go to link" placement="top" class="ms-2">
                        <i class="fas fa-external-link-alt"></i>
                    </a>
                }
            </div>
        }
        <div class="notifications-pagination">
            <app-standard-pagination footer [data]="notifications" [paginationDetails]="getPaginationDetails(notifications)" (pageChange)="changePage($event)"> </app-standard-pagination>
        </div>
    } @else {
        <div class="no-data">
            <i class="fas fa-bell fa-lg fa-5x"></i>
            <div>{{ 'notifications.noData' | translation | async }}</div>
        </div>
    }
</div>

<ngx-ui-loader [loaderId]="'notifications-loader'"></ngx-ui-loader>
