import { Injectable } from '@angular/core';
import { Asset, DeviceAnalytics, DeviceWorkflow, EdgeDevice, TenantWorkflowModule } from '@api';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from './breadcrumb';

export const HomeBreadcrumb = new Breadcrumb('home.name', '/tenant');

export const AlertsBreadcrumb = new Breadcrumb('alerts.name.plural', '/alerts');

export const AssetGroupsBreadcrumb = new Breadcrumb('assetGroups.name.plural', '/asset-groups');

export const AssetGroupBreadcrumb = (assetGroupName: string, assetGroupId: string) => new Breadcrumb(assetGroupName, `/asset-groups/${assetGroupId}`);

export const AssetGroupsAssetBreadcrumb = (assetGroupId: string, asset: Asset) => new Breadcrumb(asset.name, `/asset-groups/${assetGroupId}/assets/${asset.id}`, asset.deviceStatus);

export const EdgeDevicesBreadcrumb = new Breadcrumb('devices.name.plural', '/devices');

export const DashboardsBreadcrumb = new Breadcrumb('dashboards.name', '/dashboards');

export const EdgeDeviceBreadcrumb = (device: EdgeDevice) => new Breadcrumb(device.name, `/devices/${device.id}`, device.deviceStatus);

export const EdgeDeviceDetailsBreadcrumb = (device: EdgeDevice) => new Breadcrumb(device.name, null, device.deviceStatus);

export const WorkflowsBreadcrumb = (workflow: DeviceWorkflow) => new Breadcrumb('workflows.name.plural', `/devices/${workflow.edgeDeviceId}/workflows`);

export const WorkflowBreadcrumb = (workflow: DeviceWorkflow) => new Breadcrumb(workflow.name, `/devices/${workflow.edgeDeviceId}/workflows/${workflow.id}`);

export const AnalyticsBreadcrumb = (analytic: DeviceAnalytics) => new Breadcrumb('analytics.name.plural', `/devices/${analytic.edgeDeviceId}/analytics`);

export const AssetsBreadcrumb = (device: EdgeDevice) => new Breadcrumb('assets.name.plural', `/devices/${device.id}`);

export const AssetBreadcrumb = (device: EdgeDevice, asset: Asset) => new Breadcrumb(asset.name, `/devices/${device.id}/assets/${asset.id}`, asset.deviceStatus);

export const AssetDetailsBreadcrumb = (asset: Asset) => new Breadcrumb(asset.name, null, asset.deviceStatus);

export const AdministrationBreadcrumb = new Breadcrumb('administration.name', '/tenant/administration');

export const UserGroupBreadcrumb = new Breadcrumb('administration.userGroups.name', '/tenant/administration/groups');

export const WorkflowModulesOverviewBreadcrumb = new Breadcrumb('workflows.modules.workflowModulesOverview', '/workflows/modules');

export const WorkflowModuleBreadcrumb = (workflowModule: TenantWorkflowModule) => new Breadcrumb(workflowModule.name, `/workflows/modules/${workflowModule.id}`);

export const WorkflowModuleHistoryBreadcrumb = (workflowModule: TenantWorkflowModule) => new Breadcrumb('workflows.modules.history', `/workflows/modules/${workflowModule.id}/history`);

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService {
    private subject = new BehaviorSubject<Breadcrumb[]>([]);
    public navigatedPath$ = this.subject.asObservable();

    public setNavigatedPath(path: Breadcrumb[]): void {
        this.subject.next(path);
    }
}
