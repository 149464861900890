import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { Breadcrumb } from 'src/app/shared/services/breadcrumbs/breadcrumb';
import { BreadcrumbsService } from 'src/app/shared/services/breadcrumbs/breadcrumbs.service';
import languageDictionary from 'src/app/shared/transloco/default';
import { TranslationService } from 'src/app/shared/transloco/services/translation.service';
import { TranslocoRootModule } from 'src/app/shared/transloco/transloco-root.module';
import { NestedPropertyOf } from 'src/app/shared/transloco/types/nested-key-of.type';

@Component({
    selector: 'app-error',
    standalone: true,
    imports: [CommonModule, TranslocoRootModule],
    templateUrl: './error.component.html',
    styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit {
    private activatedRoute = inject(ActivatedRoute);
    private BreadcrumbsService = inject(BreadcrumbsService);
    private translationService = inject(TranslationService);

    public message$ = this.activatedRoute.queryParams.pipe(
        map((params) => {
            const message = this.translationService.translate(`error.hints.${params.id}` as NestedPropertyOf<typeof languageDictionary>);
            return message;
        })
    );

    public ngOnInit(): void {
        const path: Breadcrumb[] = [new Breadcrumb('error.title')];
        this.BreadcrumbsService.setNavigatedPath(path);
    }
}
