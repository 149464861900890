export interface Schema {
    [key: string]: Schema | string | number | boolean | null | Schema[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateJSON(json: any, schema: Schema): string[] {
    const errors: string[] = [];

    if (typeof json !== 'object' || json === null) {
        errors.push('JSON Format is not valid.');
        return errors;
    }

    for (const key in schema) {
        if (!(key in json)) {
            errors.push(`Missing key: "${key}"`);
            continue;
        }

        const value = json[key];
        const schemaValue = schema[key];

        const isPrimitiveType = typeof schemaValue === 'string' || typeof schemaValue === 'number' || typeof schemaValue === 'boolean' || schemaValue === null;
        if (isPrimitiveType) {
            if (typeof value === typeof schemaValue) {
                continue;
            }

            errors.push(`Type mismatch for key "${key}": expected "${typeof schemaValue}", got "${typeof value}"`);
        } else if (Array.isArray(schemaValue)) {
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    errors.push(...validateJSON(value[i], schemaValue[0]));
                }

                continue;
            }

            errors.push(`Type mismatch for key "${key}": expected array, got "${typeof value}"`);
        } else {
            errors.push(...validateJSON(value, schemaValue));
        }
    }

    return errors;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function tryParseJSON(input: string, defaultValue: object = null): any {
    if (!input) {
        return defaultValue;
    }

    try {
        return JSON.parse(input);
    } catch {
        return defaultValue;
    }
}

export function isValidJSON(input?: string) {
    if (!input) {
        return false;
    }

    try {
        JSON.parse(input);
        return true;
    } catch {
        return false;
    }
}

export function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function getRandomId() {
    return Math.random().toString(36).substring(2);
}

export function toCamelCase(value: string) {
    return value
        .replace(/\s(.)/g, function ($1) {
            return $1.toUpperCase();
        })
        .replace(/\s/g, '')
        .replace(/^(.)/, function ($1) {
            return $1.toLowerCase();
        });
}
