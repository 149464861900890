import { IGeneralItem } from '../../interfaces/general-item';
import languageDictionary from '../../transloco/default';
import { NestedPropertyOf } from '../../transloco/types/nested-key-of.type';

export const notificationTypes: { [key: string]: { [key: string]: NestedPropertyOf<typeof languageDictionary> } } = {
    AssetStatusChanged: {
        title: 'assets.notifications.assetStatusChanged',
        text: 'assets.notifications.assetStatusChangedMessage',
    },
    EdgeDeviceStatusChanged: {
        title: 'devices.notifications.edgeDeviceStatusChanged',
        text: 'devices.notifications.edgeDeviceStatusChangedMessage',
    },
    NewAsset: {
        title: 'assets.notifications.newAsset',
        text: 'assets.notifications.newAssetMessage',
    },
    DeleteAsset: {
        title: 'assets.notifications.deleteAsset',
        text: 'assets.notifications.deleteAssetMessage',
    },
    DataExportCompleted: {
        title: 'notifications.dataExportCompleted',
        text: 'notifications.dataExportCompletedMessage',
    },
    DataExportFailed: {
        title: 'notifications.dataExportFailed',
        text: 'notifications.dataExportFailedMessage',
    },
    IOLinkError: {
        title: 'notifications.ioLinkError',
        text: 'notifications.ioLinkErrorMessage',
    },
};

export const notificationSeverity: IGeneralItem[] = [
    { id: 0, name: 'notifications.informationSeverity' },
    { id: 1, name: 'notifications.warningSeverity' },
    { id: 2, name: 'notifications.errorSeverity' },
];

export const notificationType: IGeneralItem[] = [
    { id: 'AssetStatusChanged', name: 'assets.notifications.assetStatusChanged' },
    { id: 'EdgeDeviceStatusChanged', name: 'devices.notifications.edgeDeviceStatusChanged' },
    { id: 'NewAsset', name: 'assets.notifications.newAsset' },
    { id: 'DeleteAsset', name: 'assets.notifications.deleteAsset' },
    { id: 'DataExportCompleted', name: 'notifications.dataExportCompleted' },
    { id: 'DataExportFailed', name: 'notifications.dataExportFailed' },
    { id: 'IOLinkError', name: 'notifications.ioLinkError' },
];
