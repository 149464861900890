<header class="header">
    <img [src]="tenantId + '/api/tenant/logo'" (load)="imageLoaded()" [class.d-none]="!imageWasLoaded" height="35" class="mb-1 me-2" />
    <span class="header-title"></span>

    <ul class="menu">
        @if (displaySettings && (hasAcceptedDisclaimer$ | async)) {
            <li class="submenu d-block d-lg-none d-xl-none">
                <i class="fas fa-search fa-lg me-1"></i>
                <ul>
                    <li class="li-height">
                        <app-search></app-search>
                    </li>
                </ul>
            </li>
            <li class="d-none d-lg-block">
                <app-search class="search-box"></app-search>
            </li>
            <li class="submenu">
                <div class="d-none d-lg-block">
                    <i class="fas fa-question-circle fa-lg me-1"></i>
                    <span>{{ 'header.help' | translation | async }}</span>
                </div>
                <div class="d-block d-lg-none d-xl-none">
                    <i class="fas fa-question-circle fa-lg me-1"></i>
                </div>
                <ul>
                    @if (hasTour) {
                        <li>
                            <a (click)="startTour()">
                                <i class="fas fa-directions fa-lg me-1"></i>
                                <span>{{ 'header.startTour' | translation | async }}</span>
                            </a>
                        </li>
                    }
                    <li>
                        <a (click)="showShortcutKeys()">
                            <i class="fas fa-keyboard fa-lg me-1"></i>
                            <span>{{ 'header.keyboardShortcuts' | translation | async }}</span>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" [href]="gettingStartedLink$ | async">
                            <i class="fas fa-book-open fa-lg me-1"></i>
                            <span>{{ 'header.gettingStarted' | translation | async }}</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="notifications" [class.notifications-hover]="isNotificationsShown" #notificationArea>
                <div (click)="showNotifications()">
                    <div class="d-none d-lg-block">
                        <i class="fas fa-bell fa-lg me-1"></i>
                        <span class="show-info">{{ 'header.notifications' | translation | async }}</span>
                    </div>
                    <div class="d-block d-lg-none d-xl-none">
                        <i class="fas fa-bell fa-lg me-1"></i>
                    </div>
                </div>
                <div class="notifications-area" [hidden]="!isNotificationsShown">
                    <a (click)="hideNotifications()" class="notifications-close-btn"><i class="fas fa-times"></i></a>
                    <app-notifications></app-notifications>
                </div>
            </li>
        }

        <li>
            <div (click)="changeLanguage()">
                <div class="d-none d-lg-block">
                    <i class="fi fi-{{ language$ | async }} fis flag-icon me-1"></i>
                    <span>{{ 'defaults.lang' | translation | async }}</span>
                </div>
                <div [ngbTooltip]="'defaults.lang' | translation | async" class="d-block d-lg-none d-xl-none">
                    <i class="fi fi-{{ language$ | async }} fis flag-icon"></i>
                </div>
            </div>
        </li>

        @if ((isUserLoggedIn$ | async) === false) {
            <li [ngbTooltip]="'actions.login' | translation | async">
                <a (click)="login()">
                    <i class="fas fa-sign-in-alt fa-lg me-1"></i>
                    <span>{{ 'actions.login' | translation | async }}</span>
                </a>
            </li>
        }

        @if (isUserLoggedIn$ | async) {
            <li [ngbTooltip]="'actions.logout' | translation | async">
                <a (click)="logout()">
                    <div class="d-none d-lg-block">
                        <i class="fas fa-sign-out-alt fa-lg me-1"></i>
                        <span>{{ username$ | async }}</span>
                    </div>
                    <div class="d-block d-lg-none d-xl-none">
                        <i class="fas fa-sign-out-alt fa-lg me-1"></i>
                    </div>
                </a>
            </li>
        }
    </ul>
</header>
