import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ShepherdService } from 'angular-shepherd';
import { IHelpGuideItemDefinition } from '../interfaces/HelpGuideItemDefinition.interface';
import { CompleteTour } from '../state/application.actions';
import { TranslationService } from '../transloco/services/translation.service';

@Injectable({ providedIn: 'root' })
export class HelpGuideService {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    private steps = [] as Array<any>;

    private translationService = inject(TranslationService);

    public get hasTour() {
        return this.steps.length > 0;
    }

    constructor(
        private shepherdService: ShepherdService,
        private store: Store
    ) {
        this.configureHelpTour();
    }

    public reset() {
        this.steps = [];
    }

    public addTourSteps(items: IHelpGuideItemDefinition[]) {
        items.forEach((item) => this.addStep(item.title, item.text, item.attachToElement, item.attachToPosition));
    }

    public addStep(
        title: string,
        text: string[] | string,
        attachToElement: string,
        attachToPosition?: 'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end'
    ): void;
    public addStep(title: string, text?: string[] | string, attachToElement?: string, attachToPosition?: string) {
        if (typeof title === 'object') {
            this.steps.push(title);
            return;
        }

        if (attachToPosition == null) {
            attachToPosition = 'auto';
        }

        if (!Array.isArray(text)) {
            text = [text];
        }

        const step = {
            title,
            id: title.replace(/[^A-Za-z0-9]+/, ''),
            text,
            attachTo: {
                element: attachToElement,
                on: attachToPosition,
            },
        };
        this.steps.push(step);
    }

    public start() {
        if (this.shepherdService.tourObject != null) {
            this.shepherdService.tourObject.off(this.translationService.translate('actions.complete'), undefined);
            this.shepherdService.tourObject.off(this.translationService.translate('actions.cancel'), undefined);
            this.shepherdService.tourObject.cancel();
        }

        if (this.steps.length == 0) {
            return;
        }

        this.configureSteps();
        this.shepherdService.addSteps(this.steps);

        this.shepherdService.tourObject.on(this.translationService.translate('actions.complete'), () => this.store.dispatch(new CompleteTour()));
        this.shepherdService.tourObject.on(this.translationService.translate('actions.cancel'), () => this.store.dispatch(new CompleteTour()));

        this.shepherdService.start();
    }

    private configureSteps() {
        const skipButton = {
            action: function () {
                return this.cancel();
            },
            secondary: true,
            text: this.translationService.translate('actions.skip'),
            classes: 'btn btn-secondary',
        };

        const nextButton = (text?: string) => ({
            action: function () {
                return this.next();
            },
            text: text == null ? this.translationService.translate('actions.next') : text,
            classes: 'btn btn-primary',
        });

        const backButton = {
            action: function () {
                return this.back();
            },
            secondary: true,
            text: this.translationService.translate('actions.back'),
            classes: 'btn btn-secondary',
        };

        for (let i = 0; i < this.steps.length; i++) {
            const firstStep = i === 0;
            const lastStep = i === this.steps.length - 1;

            if (firstStep) {
                this.steps[i].buttons = [skipButton, nextButton()];
            } else if (lastStep) {
                this.steps[i].buttons = [backButton, nextButton(this.translationService.translate('actions.done'))];
            } else {
                this.steps[i].buttons = [backButton, nextButton()];
            }
        }
    }

    private configureHelpTour() {
        this.shepherdService.defaultStepOptions = {};
        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;
    }
}
